import { useEffect, useRef, useState } from "react";
import { Button } from "../Component/Button"
import NumberCounter from "../Component/NumberAnimation"
import { useNavigate } from "react-router-dom";

export const Home = () => {
    const sectionRef = useRef(null);
    const [inView, setInView] = useState(false);
    const navigate = useNavigate()

    // Intersection Observer to detect when the element is in view
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setInView(true); // Element is in view
                }
            },
            { threshold: 0.5 } // Adjust based on how much of the element should be visible to trigger
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="">
            <div className="h-[70vh] sm:h-[80vh] lg:h-[80vh]">
                <div className=" relative h-full" >
                    <div className="absolute inset-0 bg-black opacity-70"></div> {/* Dark overlay */}
                    {/* <img
                        src="/images/img_three_hexagons.png"
                        alt="Threehexagons"
                        className="absolute z-[0] w-full h-[100%] top-0 "
                    /> */}
                    <video
                        className="h-[100%] w-full  object-cover"
                        controls={false}
                        loop
                        autoPlay
                        muted
                    >
                        <source src="https://rutain-data.s3.ap-south-1.amazonaws.com/HomePage.mp4" type="video/webm" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[10%] lg:-translate-x-[100%] lg:-translate-y-[50%]  w-[100%] lg:w-[45%] px-5">
                        <div className="flex flex-col">
                            <div className="  flex flex-col items-start gap-[5px] lg:gap-[18px]">

                                <p
                                    className='relative bg-gradient11 bg-clip-text font-poppins text-[16px] lg:text-[20px] font-normal text-transparent  bg-gradient-to-r from-[#25BEB2] to-[#D0F7F0] '         >
                                    Welcome to
                                    <img
                                        src="/images/img_three_hexagons.svg"
                                        alt="Threehexagons"
                                        className="absolute right-[-14px] top-[-6px] z-[2] h-[16px] w-[16px] lg:ml-0"
                                    />
                                </p>
                                <h1
                                    className='bg-gradient12 bg-clip-text font-poppins text-[20px] font-semibold   sm:text-[38px] text-transparent  bg-gradient-to-r from-[#25BEB2] to-[#D0F7F0]'

                                >
                                    Rutain Pharma Chem
                                </h1>
                                <h2
                                    className='u-[40%] font-poppins text-[14px] md:text-[16px] lg:text-[20px] font-bold leading-[27px] text-gray-100 lg:w—full'

                                >
                                    Connect with one of the top sellers of Pharmaceutical Chemicals from Gujarat.
                                </h2>
                                <Button
                                    shape="round"
                                    className="min-w-[118px] rounded-[12px] px-5 font-poppins font-medium text-black bg-[#a1eee1] shadow-[0_4px_163px_rgba(105,223,208,0.6)] hover:shadow-[0_4px_32px_rgba(105,223,208,1)] transition-all duration-300 ease-in-out"
                                    onClick={() => navigate('/product')}
                                >
                                    Find More
                                </Button>


                            </div >
                        </div >
                    </div >
                </div >
            </div>
            <div className="container overflow-hidden  flex flex-col h-[150vh] min-h-[100vh] mb-10 lg:mb-0 lg:h-auto lg:flex-row justify-between gap-10" >
                <div className=" relative  w-[100%] lg:w-[50%]">
                    <div className=" w-[100%] absolute top-1/2 left-1/2 transform -translate-x-[65%] -translate-y-[0%] lg:-translate-x-[65%] lg:-translate-y-[80%] ">
                        <div className="relative">
                            <img src="/images/Vector1.png" alt="rutain-pharma" className="w-full relative" />
                            <div className=' absolute top-1/2 left-1/2 transform -translate-x-[60%] -translate-y-[100%]' ref={sectionRef} >
                                <p className='font-poppins ml-6 text-[20px] lg:text-[52px] font-bold text-transparent bg-clip-text  bg-gradient-to-b from-[#072C2C] to-[#179292]'>
                                    <NumberCounter text={30} mdText={52} number={2018} />
                                </p>
                                <p className='font-inter ml-1 text-[11px] lg:text-[16px]  font-medium text-transparent bg-clip-text  bg-gradient-to-b from-[#072C2C] to-[#179292]'>
                                    Year of Establishment
                                </p>
                            </div>
                        </div >
                    </div>
                    <div className=" w-[100%]  absolute top-1/2 left-1/2 transform -translate-x-[30%] -translate-y-[-15%] lg:-translate-x-[32%] lg:-translate-y-[60%]">
                        <div className="relative">
                            <img src="/images/Vector2.png" alt="rutain-pharma" className="w-full" />
                            <div className=' absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[60%]'  >
                                <p className='font-poppins ml-9 text-[32px] lg:text-[52px] font-bold text-transparent bg-clip-text  bg-gradient-to-b from-[#A70D3B] to-[#FF9DBA]'>
                                    <NumberCounter text={30} mdText={52} number={10} />%
                                    {/* 10% */}
                                </p>
                                <p className='font-inter ml-5 text-[11px] lg:text-[16px]  font-medium text-transparent bg-clip-text  bg-gradient-to-b from-[#A70D3B] to-[#FF9DBA]'>
                                    Export Percentage
                                </p>
                            </div>
                        </div >
                    </div>
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-[65%] -translate-y-[-32%] lg:-translate-x-[65%] lg:-translate-y-[40%]  w-[100%] ">
                        <div className="relative">
                            <img src="/images/Vector3.png" alt="rutain-pharma" className="w-full" />
                            <div className=' absolute top-1/2 left-1/2 transform -translate-x-[70%] -translate-y-[70%] '  >
                                <p className='font-poppins text-center ml-3 text-[32px] lg:text-[52px] font-bold text-transparent bg-clip-text  bg-gradient-to-b from-[#A70D3B] to-[#B72E57]'>
                                    <NumberCounter text={30} mdText={52} number={40} />
                                    {/* 15 */}
                                </p>
                                <p className='font-inter ml-5 text-[11px] lg:text-[16px]  font-medium text-transparent bg-clip-text  bg-gradient-to-b from-[#A70D3B] to-[#B72E57]'>
                                    No of Employees
                                </p>
                            </div>
                        </div >
                    </div>
                    <div className="  w-[100%]  absolute top-1/2 left-1/2 transform -translate-x-[30%] -translate-y-[-68%] lg:-translate-x-[32%] lg:-translate-y-[20%]">
                        <div className="relative">
                            <img src="/images/Vector4.png" alt="rutain-pharma" className="w-full" />
                            <div className=' absolute top-1/2 left-1/2 transform -translate-x-[60%] -translate-y-[30%] '  >
                                <p className='font-poppins text-center ml-6 text-[32px] lg:text-[52px] font-bold text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                    {/* 02 */}
                                    <NumberCounter text={30} mdText={52} number={1} />
                                </p>
                                <p className='font-inter ml-9 w-[80%] text-center text-[11px] lg:text-[16px]  font-medium text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                    No of Production Units
                                </p>
                            </div>
                        </div >
                    </div>
                </div>
                <div className="w-[100%] lg:w-[50%]  text-center lg:py-24 lg:pr-20 px-3 font-poppins">
                    <p className="relative max-w-fit text-[32px] lg:text-[48px]  font-extrabold text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA] mx-auto">About <img src="/images/img_three_hexagons.svg" alt="rutain-pharma" className="absolute top-[0px] right-[-20px] w-6" /></p>
                    <p className="text-[20px] lg:text-[24px] mb-1 lg:mb-3 font-semibold  text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA]">Rutain Pharma Chem</p>
                    <p className="text-[14px] lg:text-[16px]">Rutain Pharma Chem is committed to providing high-quality medicinal products that meet statutory and regulatory requirements, assuring product purity and safety. We specialize in developing and producing innovative APIs such as Pharmaceutical Raw Material, Pharma Chemicals, Minoxidil, Pregabalin, Pantoprazole, Artemether, 2-Amino Acetophenone, etc. We are also well-equipped with the skills and resources to take an API from basic formulation to commercial-scale manufacture while adhering to all statutory inspections and rules and participating in the development, marketing, and manufacturing. Our company continually updates its knowledge and identifies technologically challenging specialized and sophisticated APIs.</p>
                    <Button
                        shape="round"
                        className="hover:border-b border-[#a1eee1] min-w-[118px] mx-auto mt-10 rounded-[12px] px-5 font-poppins font-medium text-black bg-[#a1eee1] shadow-[0_4px_163px_rgba(105,223,208,0.6)] hover:shadow-[0_4px_32px_rgba(105,223,208,1)] transition-all duration-300 ease-in-out"
                        onClick={() => navigate('/company-profile')}
                    >
                        Read More
                    </Button>
                </div>
            </div>
            <div className=" relative h-[950px] bg-[#072C2C] hidden md:block py-14">
                <img src="/images/bgLeaf.png" alt="rutain-pharma" className="absolute top-0 left-0" />
                <img src="/images/bgLeaf2.png" alt="rutain-pharma" className="absolute bottom-0 right-0" />
                <h1 className="text-[38px] font-bold md:text-[48px] w-[270px] text-center absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[50%]  text-transparent bg-clip-text  bg-gradient-to-r from-[#D0F7F0] to-[#25BEB2]">The Sustainable Initiatives</h1>
                <div className="relative h-[85%] my-25">
                    <div className="absolute left-1/2 transform -translate-x-[50%]  ">
                        <img src="/images/Vector5.png" alt="rutain-pharma" className="" />
                        <div className=' absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[50%] text-center'  >
                            <img src="/images/recycle.svg" alt="rutain-pharma" className="w-6 h-6 mx-auto" />
                            <p className='font-poppins w-[100%]  text-[26px] font-bold text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                {/* 5545  */}
                                <NumberCounter number={5545} text={26} mdText={26} /><br />  KL / Annum
                            </p>
                            {/* <p className='font-poppins ml-20 text-[20px]  text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                    KL/ Annhum
                                </p> */}
                            <p className='font-inter mt-2 w-[100%] text-center text-[16px]  font-medium text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                Water Recycled<br />
                                <p className="my-2">Through STP Plant</p>
                            </p>
                        </div>
                    </div >
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-[125%] -translate-y-[72.5%] ">
                        <img src="/images/Vector5.png" alt="rutain-pharma" className="" />
                        <div className=' absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[50%] text-center'  >
                            <img src="/images/dustbin.svg" alt="rutain-pharma" className="w-6 h-6 mx-auto" />
                            <p className='font-poppins w-[100%] text-[26px] font-bold text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                <NumberCounter number={5566} text={26} mdText={26} /><br />  mt / Annum
                            </p>
                            {/* <p className='font-poppins ml-20 text-[20px]  text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                    KL/ Annhum
                                </p> */}
                            <p className='font-inter mt-2 w-[100%] text-center text-[16px]  font-medium text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                Water Recycled
                            </p>
                        </div>
                    </div >
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-[125%] -translate-y-[-22.5%] ">
                        <img src="/images/Vector5.png" alt="rutain-pharma" className="" />
                        <div className=' absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[50%] text-center'  >
                            <img src="/images/cloud.svg" alt="rutain-pharma" className="w-6 h-6 mx-auto" />
                            <p className='font-poppins w-[100%] text-[26px] font-bold text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                <NumberCounter number={0} text={26} mdText={26} /> %
                            </p>
                            {/* <p className='font-poppins ml-20 text-[20px]  text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                    KL/ Annhum
                                </p> */}
                            <p className='font-inter leading-[1.5]  w-[100%] text-center text-[16px]  font-medium text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                Direct Water Load
                                <br />
                                On 8500 Mt<br />
                                Production
                            </p>
                        </div>
                    </div >
                    <div className="absolute top-full left-1/2 transform -translate-x-[50%] -translate-y-[50%]">
                        <img src="/images/Vector5.png" alt="rutain-pharma" className="" />
                        <div className=' absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[50%] text-center'  >
                            <img src="/images/SaveWater.svg" alt="rutain-pharma" className="w-6 h-6 mx-auto" />
                            <p className='font-poppins w-[100%] text-[26px] font-bold text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                <NumberCounter number={5569} text={26} mdText={26} /> <br /> MT / Annum
                            </p>
                            {/* <p className='font-poppins ml-20 text-[20px]  text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                    KL/ Annhum
                                </p> */}
                            <p className='w-[90%] font-inter mx-auto leading-[1.5]   text-center text-[16px]  font-medium text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                Waste Converted To Energy Through Co-Processing
                            </p>
                        </div>
                    </div >
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-[-24.5%] -translate-y-[-22.5%]">
                        <img src="/images/Vector5.png" alt="rutain-pharma" className="" />
                        <div className=' absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[50%] text-center'  >
                            <img src="/images/leaf.svg" alt="rutain-pharma" className="w-6 h-6 mx-auto" />
                            <p className='font-poppins leading-[1.3] w-[100%] text-[24px] font-bold text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                <NumberCounter number={21} text={26} mdText={26} /> <br /> MLD/ Annum
                            </p>
                            {/* <p className='font-poppins ml-20 text-[20px]  text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                    KL/ Annhum
                                </p> */}
                            <p className='font-inter mx-auto leading-[1.5]  w-[100%] text-center text-[16px]  font-medium text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                Recycling of Treated Vadodara Municipal Cooperation Sewage water
                            </p>
                        </div>
                    </div >
                </div>
                <div className="absolute top-1/2 left-1/2 transform -translate-x-[-24.5%] -translate-y-[93.5%]">
                    <img src="/images/Vector5.png" alt="rutain-pharma" className="" />
                    <div className=' absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[50%] text-center '  >
                        <img src="/images/fuel.svg" alt="rutain-pharma" className="w-6 h-6 mx-auto" />
                        <p className='font-poppins leading-[1.3] w-[100%] text-[28px] font-bold text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                            <NumberCounter number={23} text={26} mdText={26} /> %
                        </p>
                        {/* <p className='font-poppins ml-20 text-[20px]  text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                    KL/ Annhum
                                    </p> */}
                        <p className='font-inter mx-auto leading-[1.5]  w-[90%] text-center text-[16px]  font-medium text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                            Reduction In Carbon Emission Using Renewable Fuel
                        </p>
                    </div>
                </div>
            </div >
            <div className=" relative bg-[#072C2C] md:hidden">
                <img src="/images/bgLeaf.png" alt="rutain-pharma" className="absolute top-0 left-0 w-[30%]" />
                <img src="/images/bgLeaf2.png" alt="rutain-pharma" className="absolute bottom-0 right-0 w-[30%]" />
                <h1 className="text-[38px] w-full text-center  text-transparent bg-clip-text  bg-gradient-to-r from-[#D0F7F0] to-[#25BEB2]">The Sustainable Initiatives</h1>
                <div className="grid grid-cols-2 sm:grid-cols-3">
                    <div className=" relative  ">
                        <img src="/images/Vector5.png" alt="rutain-pharma" className="" />
                        <div className=' absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[50%] text-center'  >
                            <img src="/images/recycle.svg" alt="rutain-pharma" className="w-3 h-3 mx-auto" />
                            <p className='font-poppins w-[100%]  text-[15px] font-bold text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                {/* 5545  */}
                                <NumberCounter number={5545} text={18} mdText={26} /><br />  KL / Annum
                            </p>
                            {/* <p className='font-poppins ml-20 text-[20px]  text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                    KL/ Annhum
                                </p> */}
                            <p className='font-inter mt-2 w-[100%] text-center text-[10px]  font-medium text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                Water Recycled<br />
                                <p className="my-2">Through STP Plant</p>
                            </p>
                        </div>
                    </div >
                    <div className="relative ">
                        <img src="/images/Vector5.png" alt="rutain-pharma" className="" />
                        <div className=' absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[50%] text-center'  >
                            <img src="/images/dustbin.svg" alt="rutain-pharma" className="w-3 h-3 mx-auto" />
                            <p className='font-poppins w-[100%] text-[15px] font-bold text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                <NumberCounter number={5566} text={18} mdText={26} /><br />  mt / Annum
                            </p>
                            {/* <p className='font-poppins ml-20 text-[20px]  text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                    KL/ Annhum
                                </p> */}
                            <p className='font-inter mt-2 w-[100%] text-center text-[10px]  font-medium text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                Water Recycled
                            </p>
                        </div>
                    </div >
                    <div className=" relative">
                        <img src="/images/Vector5.png" alt="rutain-pharma" className="" />
                        <div className=' absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[50%] text-center'  >
                            <img src="/images/cloud.svg" alt="rutain-pharma" className="w-3 h-3 mx-auto" />
                            <p className='font-poppins w-[100%] text-[15px] font-bold text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                <NumberCounter number={0} text={18} mdText={26} /> %
                            </p>
                            {/* <p className='font-poppins ml-20 text-[20px]  text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                    KL/ Annhum
                                </p> */}
                            <p className='font-inter leading-[1.5]  w-[100%] text-center text-[10px]  font-medium text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                Direct Water Load
                                <br />
                                On 8500 Mt<br />
                                Production
                            </p>
                        </div>
                    </div >
                    <div className="relative">
                        <img src="/images/Vector5.png" alt="rutain-pharma" className="" />
                        <div className=' absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[50%] text-center'  >
                            <img src="/images/SaveWater.svg" alt="rutain-pharma" className="w-3 h-3 mx-auto" />
                            <p className='font-poppins w-[100%] text-[15px] font-bold text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                <NumberCounter number={5569} text={18} mdText={26} /> <br /> MT / Annum
                            </p>
                            {/* <p className='font-poppins ml-20 text-[20px]  text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                    KL/ Annhum
                                </p> */}
                            <p className='w-[90%] font-inter mx-auto leading-[1.5]   text-center text-[10px]  font-medium text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                Waste Converted To Energy Through Co-Processing
                            </p>
                        </div>
                    </div >
                    <div className="relative">
                        <img src="/images/Vector5.png" alt="rutain-pharma" className="" />
                        <div className=' absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[50%] text-center'  >
                            <img src="/images/leaf.svg" alt="rutain-pharma" className="w-3 h-3 mx-auto" />
                            <p className='font-poppins leading-[1.3] w-[100%] text-[15px] font-bold text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                <NumberCounter number={21} text={18} mdText={26} /> <br /> MLD/ Annum
                            </p>
                            {/* <p className='font-poppins ml-20 text-[20px]  text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                    KL/ Annhum
                                </p> */}
                            <p className='font-inter mx-auto leading-[1.5]  w-[100%] text-center text-[8px]  font-medium text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                Recycling of Treated Vadodara Municipal Cooperation Sewage water
                            </p>
                        </div>
                    </div >
                    <div className="relative">
                        <img src="/images/Vector5.png" alt="rutain-pharma" className="" />
                        <div className=' absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[50%] text-center '  >
                            <img src="/images/fuel.svg" alt="rutain-pharma" className="w-3 h-3 mx-auto" />
                            <p className='font-poppins leading-[1.3] w-[100%] text-[15px] font-bold text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                <NumberCounter number={23} text={18} mdText={26} /> %
                            </p>
                            {/* <p className='font-poppins ml-20 text-[20px]  text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                    KL/ Annhum
                                    </p> */}
                            <p className='font-inter mx-auto leading-[1.5]  w-[90%] text-center text-[10px]  font-medium text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]'>
                                Reduction In Carbon Emission Using Renewable Fuel
                            </p>
                        </div>
                    </div>
                </div>
            </div >
            <div className="relative">
                <div className="container w-[100%] ">
                    <div className=" mx-auto w-fit mt-20 mb-10 relative z-[20]">
                        <h1 className="relative text-[40px] lg:text-[48px] font-extrabold font-poppins text-transparent bg-clip-text bg-gradient-to-r from-[#175856] to-[#32BEBA]">
                            Our Products
                            <img
                                src="/images/img_three_hexagons.svg"
                                alt="rutain-pharma"
                                className="absolute top-[5px] right-[-20px] w-6"
                            />
                        </h1>
                    </div>
                    <img
                        src="/images/bgLeaf3.png"
                        alt="rutain-pharma"
                        className="absolute hidden lg:block top-[-75px] right-0 z-[5]"
                    />
                    <div className="grid sm:grid-cols-3 grid-col-1 gap-5 rounded-full bg-white z-[10]">
                        <div
                            className="rounded-[10%] overflow-hidden cursor-pointer h-[350px] sm:h-[300px] md:h-[350px] 2xl:h-[494px] relative "
                            style={{ boxShadow: '7px 7px 0px rgba(233, 31, 100, 0.5)' }}
                            onClick={() => navigate('/product/API-Intermediates')}
                        >
                            <video
                                className="absolute top-0 left-0 w-full h-full object-cover transform transition-all duration-300 ease-in-out hover:scale-110"
                                controls={false}
                                loop
                                autoPlay
                                muted
                            >
                                <source src="https://rutain-data.s3.ap-south-1.amazonaws.com/intermediate.mp4" type="video/webm" />
                                Your browser does not support the video tag.
                            </video>
                        </div>

                        <div className="rounded-[10%] overflow-hidden cursor-pointer h-[350px] sm:h-[300px] md:h-[350px] 2xl:h-[494px] relative "
                            style={{ boxShadow: '7px 7px 0px rgba(233, 31, 100, 0.5)' }}
                            onClick={() => navigate('/product/Bulk-Drugs-API')}>
                            <video
                                className="absolute top-0 left-0 w-full h-full object-cover transform transition-all duration-300 ease-in-out hover:scale-110"
                                controls={false}
                                loop
                                autoPlay
                                muted
                            >
                                <source src="https://rutain-data.s3.ap-south-1.amazonaws.com/drugs.mp4" type="video/webm" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <div className="rounded-[10%] overflow-hidden cursor-pointer h-[350px] sm:h-[300px] md:h-[350px] 2xl:h-[494px] relative "
                            style={{ boxShadow: '7px 7px 0px rgba(233, 31, 100, 0.5)' }}
                            onClick={() => navigate('/product/API-Intermediates')}>
                            <video
                                className="absolute top-0 left-0 w-full h-full object-cover transform transition-all duration-300 ease-in-out hover:scale-110"
                                controls={false}
                                loop
                                autoPlay
                                muted
                            >
                                <source src="https://rutain-data.s3.ap-south-1.amazonaws.com/api.mp4" type="video/webm" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        {/* <img src="/images/product1.png" alt="rutain-pharma" />
                    <img src="/images/product2.png" alt="rutain-pharma" />
                    <img src="/images/product3.png" alt="rutain-pharma" /> */}
                    </div>
                </div>
            </div>

            <div className="container ">
                <div className="mx-auto w-fit mt-20 mb-10">
                    <h1 className="relative text-[40px] lg:text-[48px] font-semibold font-poppins text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA]">Why choose Us
                        <img src="/images/img_three_hexagons.svg" alt="rutain-pharma" className="absolute top-[5px] right-[-20px] w-6" />
                    </h1>
                </div>
                <div className="flex flex-col md:flex-row items-center  justify-between" >
                    <div className="w-[90%] md:w-[40%] 2xl:pl-20 mx-auto md:mx-0 text-center">
                        <h1 className="text-[32px] md:text-[32px] 2xl:text-[48px] my-2 2xl:my-5 font-black  font-poppins text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]">Our Vision
                        </h1>
                        <p className="2xl:text-[16px] text-[14px] font-inter leading-[1.7] text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]">
                            To address the requirements of business associates by manufacturing and developing products following regulatory regulations.To address the requirements of business associates by manufacturing and developing products following regulatory regulations.
                        </p>
                        <Button
                            shape="round"
                            className="min-w-[118px] mx-auto mt-5 rounded-[12px] px-5 font-poppins font-medium text-white bg-[#176E6A] shadow-[0_4px_163px_rgba(105,223,208,0.2)] hover:shadow-[0_4px_32px_rgba(105,223,208,1)] transition-all duration-300 ease-in-out"
                            onClick={() => navigate('/company-profile')}
                        //   style={{ boxShadow: '0 6px 30px rgba(105, 223, 208, 0.6)' }}
                        >
                            Know More
                        </Button>

                    </div>
                    <div className="md:w-[50%] w-[100%] justify-center md:justify-end mt-10 md:mt-0">
                        <img src="/images/choose1.png" alt="rutain-pharma" className=" mx-auto" />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row items-center  justify-between mt-10" >
                    <div className="w-[50%] hidden  md:flex justify-start h-fit">
                        <img src="/images/choose2.png" alt="rutain-pharma" className=" mx-auto" />
                    </div>
                    <div className="w-[90%] md:w-[40%] text-center mx-auto md:mx-0 2xl:pr-20">
                        <h1 className="text-[32px] md:text-[32px] 2xl:text-[48px] my-2 2xl:my-5 font-black  font-poppins text-transparent bg-clip-text  bg-gradient-to-b from-[#E91F64] to-[#FFA2CB]">Our Mission
                        </h1>
                        <p className="2xl:text-[16px] text-[14px] font-inter leading-[1.7] text-transparent bg-clip-text  bg-gradient-to-b from-[#FFA2CB] to-[#FFA2CB]">
                            To be known as the most reliable partner for delivering high-quality products.To address the requirements of business associates by manufacturing and developing products following regulatory regulations.
                        </p>
                        <Button
                            shape="round"
                            className="min-w-[118px] mx-auto mt-5 rounded-[12px] px-5 font-poppins font-medium text-white bg-[#E91F64] shadow-[0_4px_163px_rgba(233,31,100,0.2)] hover:shadow-[0_4px_32px_rgba(233,31,100,1)] transition-all duration-300 ease-in-out"
                            onClick={() => navigate('/company-profile')}
                        //   style={{ boxShadow: '0 6px 30px rgba(233, 31, 100, 0.5)' }} // Inline shadow
                        >
                            Know More
                        </Button>


                    </div>
                </div>
                <div className="flex flex-col md:flex-row items-center justify-between mt-10" >
                    <div className="md:w-[40%] w-[90%] mx-auto md:mx-0 2xl:pl-20 text-center">
                        <h1 className="text-[32px] md:text-[32px] 2xl:text-[48px] my-2 2xl:my-5 font-black  font-poppins text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]">Our Team
                        </h1>
                        <p className="2xl:text-[16px] text-[14px] font-inter leading-[1.7] text-transparent bg-clip-text  bg-gradient-to-b from-[#175856] to-[#32BEBA]">
                            We have met our goals in product development, technical help for commercial production, managing pharmaceutical product supply We have met our goals in product development, technical help for commercial production, managing pharmaceutical product supply
                        </p>
                        <Button
                            shape="round"
                            className="min-w-[118px] mx-auto mt-5 rounded-[12px] px-5 font-poppins font-medium text-white bg-[#176E6A] shadow-[0_4px_163px_rgba(105,223,208,0.6)] hover:shadow-[0_4px_32px_rgba(105,223,208,1)] transition-all duration-300 ease-in-out"
                            onClick={() => navigate('/company-profile')}
                        //   style={{ boxShadow: '0 6px 30px rgba(105, 223, 208, 0.6)' }}
                        >
                            Know More
                        </Button>
                    </div>
                    <div className="md:w-[50%] w-[100%] justify-center md:justify-end mt-10 md:mt-0">
                        <img src="/images/choose3.png" alt="rutain-pharma" className="mx-auto" />
                    </div>
                </div>
            </div>
            <div className="container ">
                <div className="mx-auto w-fit mt-20 mb-10">
                    <h1 className="relative text-[40px] lg:text-[48px] font-extrabold font-poppins text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA]">Blogs
                        <img src="/images/img_three_hexagons.svg" alt="rutain-pharma" className="absolute top-[5px] right-[-20px] w-6" />
                    </h1>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-10  justify-center" >

                    <div
                        className="group  md:mb-10 relative overflow-hidden"
                    style={{ boxShadow: '10px 10px 0px rgba(254, 204, 227)' }}
                    >
                        <img
                            src="/images/blog.png"
                            alt="rutain-pharma"
                            className="w-full h-auto transition-transform duration-300 group-hover:scale-110"
                        />
                        <div className="absolute inset-0 bg-black opacity-70"></div> {/* Dark overlay */}
                        <p className="absolute right-4 top-3 text-[#fff] 2xl:text-[16px] text-[14px]">30th Sep, 2024</p>
                        <div className="absolute bottom-0 z-10 2xl:px-10 px-2 py-3">
                            <p className="text-[14px] 2xl:text-[16px] font-inter text-transparent my-1 bg-clip-text bg-gradient-to-r from-[#DE5996] to-[#FECCE3]">
                            Celebrating the Backbone of Healthcare: World Pharmacist Day
                            </p>
                            <div className="flex gap-5">
                                <p
                                    className="hover:border-b border-[#FECCE3] flex gap-1 underline text-transparent bg-clip-text bg-gradient-to-r from-[#DE5996] to-[#FECCE3] cursor-pointer"
                                    onClick={() => navigate('/blog-details')}
                                >
                                    Read More
                                    <img src="/images/upArrow.svg" alt="rutain-pharma" className="w-4" />
                                </p>
                                <p className="text-white md:hidden text-[14px] 2xl:text-[16px] 2xl:block">5 minutes read</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="group  md:mb-10 relative overflow-hidden"
                        style={{ boxShadow: '10px 10px 0px rgba(254, 204, 227)' }}
                    >
                       <img src="/images/blog3.png" alt="rutain-pharma" className="w-full h-auto transition-transform duration-300 group-hover:scale-110" />
                        <div className="absolute inset-0 bg-black opacity-70"></div> {/* Dark overlay */}
                        <p className="absolute right-4 top-3 text-[#fff] 2xl:text-[16px] text-[14px]">30th Sep, 2024</p>
                        <div className="absolute bottom-0 z-10 2xl:px-10 px-2 py-3">
                            <p className="text-[14px] 2xl:text-[16px] font-inter text-transparent my-1 bg-clip-text bg-gradient-to-r from-[#DE5996] to-[#FECCE3]">
                            Safe and Effective Medication: Tips and Best Practices
                            </p>
                            <div className="flex gap-5">
                                <p
                                    className="hover:border-b border-[#FECCE3] flex gap-1 underline text-transparent bg-clip-text bg-gradient-to-r from-[#DE5996] to-[#FECCE3] cursor-pointer"
                                    onClick={() => navigate('/blog-details1')}
                                >
                                    Read More
                                    <img src="/images/upArrow.svg" alt="rutain-pharma" className="w-4" />
                                </p>
                                <p className="text-white md:hidden text-[14px] 2xl:text-[16px] 2xl:block">5 minutes read</p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="group  md:mb-10 relative overflow-hidden"
                        style={{ boxShadow: '10px 10px 0px rgba(254, 204, 227)' }}
                    >
                        <img
                            src="/images/blog4.png"
                            alt="rutain-pharma"
                            className="w-full h-auto transition-transform duration-300 group-hover:scale-110"
                        />
                        <div className="absolute inset-0 bg-black opacity-70"></div> {/* Dark overlay */}
                        <p className="absolute right-4 top-3 text-[#fff] 2xl:text-[16px] text-[14px]">30th Sep, 2024</p>
                        <div className="absolute bottom-0 z-10 2xl:px-10 px-2 py-3">
                            <p className="text-[14px] 2xl:text-[16px] font-inter text-transparent my-1 bg-clip-text bg-gradient-to-r from-[#DE5996] to-[#FECCE3]">
                            Overview of the Pharma Industry
                            </p>
                            <div className="flex gap-5">
                                <p
                                    className="hover:border-b border-[#FECCE3] flex gap-1 underline text-transparent bg-clip-text bg-gradient-to-r from-[#DE5996] to-[#FECCE3] cursor-pointer"
                                    onClick={() => navigate('/blog-details2')}
                                >
                                    Read More
                                    <img src="/images/upArrow.svg" alt="rutain-pharma" className="w-4" />
                                </p>
                                <p className="text-white md:hidden text-[14px] 2xl:text-[16px] 2xl:block">5 minutes read</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className="relative overflow-hidden my-10 container">
            <div
                className="flex gap-5 w-full animate-marquee justify-end"
                style={{
                    animation: 'scrollLeft 10s linear infinite',
                }}
            >
                <img
                    src="https://rutain-data.s3.ap-south-1.amazonaws.com/duns.jpeg"
                    alt="rutain-pharma"
                    className="w-[12%]"
                />
                <img
                    src="https://rutain-data.s3.ap-south-1.amazonaws.com/certi2.jpeg"
                    alt="rutain-pharma"
                    className="w-[12%]"
                />
            </div>
        </div>


        </div>
    )
}