import React from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';

const NumberCounter = ({ number ,text,mdText}) => {
  // Intersection Observer hook to detect visibility
  const { ref, inView } = useInView({
    triggerOnce: true,  // Trigger the animation once when the element is in view
    threshold: 0.5,     // Trigger when 50% of the element is in view
  });

  const { num } = useSpring({
    from: { num: 1 },  // Start from 1
    num: inView ? number : 1,  // If in view, animate to the final number
    delay: 200,
    config: { duration: 1000 },  // Duration of the animation
  });

  return (
    <animated.p
      ref={ref}  // Attach the intersection observer
    className={`font-poppins text-[${text}px] md:text-[${mdText}px] font-black inline`} >
      {/* Convert the animated value to a number and then render */}
      {num.to((n) => Math.floor(n))}
    </animated.p>
  );
};

export default NumberCounter;
