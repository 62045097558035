import { useEffect, useState } from "react";

const CompanyProfile = () => {
    const [hoverIndex, setHoverIndex] = useState();

    const handleMouseEnter = (index) => {
        setHoverIndex(index);
    };

    const handleMouseLeave = () => {
        setHoverIndex();
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const slides = [
        { src: "https://rutain-data.s3.ap-south-1.amazonaws.com/slide1.jpeg", text: "Our Commitment", description: "we are dedicated to providing the highest quality pharmaceutical products, ensuring safety, efficacy, and reliability in every solution we deliver." },
        { src: "https://rutain-data.s3.ap-south-1.amazonaws.com/slide2.jpeg", text: "Our Expertise", description: "With decades of experience in the pharmaceutical and chemical industries, Rutain Pharma Chem excels in the development, manufacturing, and distribution of high-quality medicines and chemical formulations." },
        { src: "https://rutain-data.s3.ap-south-1.amazonaws.com/slide3.jpeg", text: "Innovation and Research", description: "innovation and research are at the heart of everything we do. We are committed to advancing healthcare by developing cutting-edge pharmaceutical solutions that address the evolving needs of patients worldwide." },
        { src: "https://rutain-data.s3.ap-south-1.amazonaws.com/slide4.jpeg", text: "Quality Assurance", description: "quality is our top priority. We adhere to the highest standards of quality assurance to ensure that every product we manufacture meets strict safety, efficacy, and regulatory requirements." },
        { src: "https://rutain-data.s3.ap-south-1.amazonaws.com/slide5.jpeg", text: "Client-Centric Approach", description: "By engaging directly with our clients, we gain a deeper insight into their specific challenges and requirements, ensuring that every solution is customized to meet their goals." },
        { src: "https://rutain-data.s3.ap-south-1.amazonaws.com/slide6.jpeg", text: "Sustainability", description: "We prioritize sourcing raw materials from suppliers who share our commitment to sustainable practices, ensuring that our products are manufactured with minimal environmental impact.`" },
    ];
    return (
        <div>
            <div className="w-full relative h-[50vh] sm:h-[50vh] lg:h-[60vh]">
                <img src='/images/company-img.jpg' alt="rutain pharma" className="w-full h-[100%] " />
                <div className="absolute inset-0 bg-black opacity-70"></div>
                <div className="2xl:text-[94px] md:left-[35%]  w-[50%] md:w-[35%]  text-[32px] md:text-[52px] font-poppins font-bold text-transparent bg-clip-text  bg-gradient-to-r from-[#25BEB2] to-[#A1EEE1] absolute top-1/2 left-1/2 transform -translate-x-[0%] -translate-y-[50%] text-center">
                    Company Profile
                </div>
            </div>
            <div className=" w-[90%] lg:w-[55%]  mx-auto text-center lg:mt-[120px] mt-[80px]  font-poppins">
                <p className="font-poppins relative max-w-fit text-[40px] lg:text-[48px] mb-5 font-extrabold text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA] mx-auto">About Us<img src="/images/img_three_hexagons.svg" alt="rutain-pharma" className="absolute top-[-10px] right-[-20px] w-[24px] h-[24px]" /></p>
                <p className="text-[14px] md:text-[16px] leading-[24px]">We are <span className="font-bold text-transparent bg-clip-text  bg-gradient-to-r from-[#072C2C] to-[#179292]">Rutain Pharma Chem,</span> a Pharmaceutical Chemicals manufacturing firm from <span className="font-bold text-transparent bg-clip-text  bg-gradient-to-r from-[#072C2C] to-[#179292]">Ankleshwar, Gujarat, India,</span> that formulates and exports a wide variety of Pharmaceutical Chemicals meeting industry safety regulations and norms. We offer quality tested and assured <span className="font-bold text-transparent bg-clip-text  bg-gradient-to-r from-[#072C2C] to-[#179292]">Minoxidil, Fenofibrate, Pantoprazole, Dextromethorphan Hbr, Artemether, 2-Amino Acetophenone, 2-(Chloromethyl)-4-methylquinazolin, etc.</span> complying to special needs of buyers from the healthcare and associated industries. These chemicals are processed, purified, tested and packed under expert supervision only. We also have a good reputation of dispatching chemicals across the market in a safe manner, with unmatched flexibility and speed. </p>
            </div>
            <div className="container mx-auto  lg:mt-[70px] mt-[50px] lg:pt-[50px] pt-[30px]">

                <div className=" mx-auto text-center  font-inter">
                    <p className="font-poppins relative max-w-fit text-[40px] lg:text-[48px]  font-extrabold text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA] mx-auto">Key Facts<img src="/images/img_three_hexagons.svg" alt="rutain-pharma" className="absolute top-[-10px] right-[-20px] w-[24px] h-[24px]" /></p>
                    <p className="text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA] font-bold text-[20px] lg:text-[22px]">Of Rutain Pharma Chem</p>
                </div>
                <div className="flex justify-between text-[#606060] border-b-[1px] px-[10px] border-[#606060]  py-5 text-[14px] md:text-[16px]">
                    <p>Nature of Business</p>
                    <p className="text-right">Manufacturer, Supplier</p>
                </div>
                <div className="flex justify-between text-[#606060] border-b-[1px] px-[10px] border-[#606060]  py-5 text-[14px] md:text-[16px]">
                    <p>GST No.</p>
                    <p className="text-right">24ABBFR0333F1ZS</p>
                </div>
                <div className="flex justify-between text-[#606060] border-b-[1px] px-[10px] border-[#606060]  py-5 text-[14px] md:text-[16px]">
                    <p>Location</p>
                    <p className="text-right">Ankleshwar, Gujarat</p>
                </div>
                <div className="flex justify-between text-[#606060] border-b-[1px] px-[10px] border-[#606060]  py-5 text-[14px] md:text-[16px]">
                    <p>Year of Establishment</p>
                    <p className="text-right">2018</p>
                </div>
                <div className="flex justify-between text-[#606060] border-b-[1px] px-[10px] border-[#606060]  py-5 text-[14px] md:text-[16px]">
                    <p>No. of Employees</p>
                    <p className="text-right">40</p>
                </div>
                <div className="flex justify-between text-[#606060] border-b-[1px] px-[10px] border-[#606060]  py-5 text-[14px] md:text-[16px]">
                    <p>No. of Production Units</p>
                    <p className="text-right">01</p>
                </div>
                {/* <div className="flex justify-between text-[#606060]  border-[#606060] px-[10px]  py-5 text-[14px] md:text-[16px]">
                    <p>No. of Production Units</p>
                    <p className="text-right">By Rail, Road & Ship</p>
                </div> */}

                <div className=" mt-[70px]  hidden md:block" >
                    <div className="py-[50px]">
                        <div className="w-[55%] mx-auto text-center font-poppins">
                            <p className="relative max-w-fit font-poppins text-[48px]  font-extrabold text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA] mx-auto">From CEO’s Desk<img src="/images/img_three_hexagons.svg" alt="rutain-pharma" className="absolute top-[-10px] right-[-20px] w-[24px] h-[24px]" /></p>
                        </div>
                        <div className="h-[70vh] w-[100%] mx-auto flex justify-center gap-3 mt-10 rounded-xl relative overflow-hidden">
                            {slides.map((slide, index) => (
                                <div
                                    key={index}
                                    className={`relative transition-all duration-300 ${hoverIndex === index ? 'w-[40%]' : 'w-[12%]'}`}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    {console.log(hoverIndex)}
                                    <img src={slide.src} alt="rutain pharma" className="h-[100%] w-[100%] object-cover rounded-[50px]" />
                                    <div className=" absolute inset-0 bg-gradient-to-t from-black/100 to-transparent opacity-90 rounded-[50px]"></div>
                                    {index !== hoverIndex && <p className={`  text-[32px]  w-[500%] font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#A1EEE1] to-[#188983] -rotate-90 absolute  left-1/2 2xl:bottom-[80%] bottom-1/2 transform -translate-x-[50%] -translate-y-[50%]  `}>
                                        {slide.text}
                                    </p>}
                                    {index === hoverIndex && (
                                        <div className="absolute bottom-[10%] left-[10%] w-[90%]">
                                            <p className="text-[24px] 2xl:text-[32px] mb-3 font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#A1EEE1] to-[#188983]">{slide.text}</p>
                                            <p className="font-bold text-[16px] 2xl:text-[20px] text-transparent bg-clip-text bg-gradient-to-r from-[#A1EEE1] to-[#188983]">{slide.description}</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="container lg:mt-[112px] mt-[80px]">
                <div className="flex justify-center flex-col">
                    <p className="font-poppins relative max-w-fit text-[40px] lg:text-[48px]  font-extrabold text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA] mx-auto">Meet Our Team<img src="/images/img_three_hexagons.svg" alt="rutain-pharma" className="absolute top-[-10px] right-[-20px] w-[24px] h-[24px]" /></p>
                    <p className="text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA] font-bold text-[22px] mx-auto">Our core team</p>
                </div>
                <div className="flex flex-col md:flex-row  mt-[70px] mx-auto md:gap-20 gap-5 justify-between" >
                    <div className=" w-[80%] mx-auto md:mx-0 md:w-[30%] flex ">
                        <img src="https://rutain-data.s3.ap-south-1.amazonaws.com/ceo-cropped.jpeg" alt="rutain-pharma" className="rounded-2xl" />
                    </div>
                    <div className="w-[100%] md:w-[60%] ">
                        <h1 className=" text-[36px]  font-bold  font-inter text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA]">Mr Chintan
                        </h1>
                        <h1 className=" text-[18px] border-b-[2px] border-[#AFAFAF] py-4 font-semibold  font-inter text-[#175856] ">Founder & CEO
                        </h1>
                        <p className="font-poppins leading-[1.7] mt-5 text-[#072C2C] ">
                            Mr Chintan Moradiya  is a visionary leader with a passion for innovation and excellence. As the CEO of Rutain Pharmachem, He has been instrumental in driving the company's growth and success, while fostering a culture of collaboration and customer-centricity.He is a highly respected and accomplished leader, known for his integrity, passion, and commitment to excellence. With a unique blend of strategic vision, operational expertise, and collaborative leadership style, he inspires and motivates our team to achieve greatness.
                        </p>
                    </div>
                </div>
                {/* <div className="flex flex-col md:flex-row gap-5 md:gap-0  mx-auto mt-[60px] lg:my-[93px] justify-between">
                    <div className=" text-center w-[100%] md:w-[30%] ">
                        <img src="/images/cto1.png" alt="rutain pharma"  className="mx-auto"/>
                        <h2 className="text-[36px] mt-3 font-inter font-bold text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA]">DR. Ipsum</h2>
                        <h4 className="text-[#175856] font-semibold mt-2 mx-5 border-b-[2px] pb-3 border-[#AFAFAF]">CTO</h4>
                        <p className="mt-3 w-[70%] text-[#072C2C] mx-auto">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                        <Button
                            shape="round"
                            className="min-w-[118px] mx-auto mt-5 rounded-[12px] px-5 font-poppins font-medium text-[#55021A] bg-[#FECCE3] shadow-[0_4px_163px_rgba(233,31,100,0.2)] hover:shadow-[0_4px_163px_rgba(233,31,100,1)] transition-all duration-300 ease-in-out"
                        //   style={{ boxShadow: '0 6px 30px rgba(233, 31, 100, 0.5)' }} // Inline shadow
                        >
                            Know More
                        </Button>
                    </div>
                    <div className=" text-center w-[100%] md:w-[30%]">
                        <img src="/images/cto2.png" alt="rutain pharma" className="mx-auto"/>
                        <h2 className="text-[36px] mt-3 font-inter font-bold text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA]">DR. Ipsum</h2>
                        <h4 className="text-[#175856] font-semibold mt-2 mx-5 border-b-[2px] pb-3 border-[#AFAFAF]">CTO</h4>
                        <p className="mt-3 w-[70%] text-[#072C2C] mx-auto">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                        <Button
                            shape="round"
                            className="min-w-[118px] mx-auto mt-5 rounded-[12px] px-5 font-poppins font-medium text-white bg-[#176E6A] shadow-[0_4px_32px_rgba(105,223,208,0.6)] hover:shadow-[0_4px_32px_rgba(105,223,208,1)] transition-all duration-300 ease-in-out"
                        //   style={{ boxShadow: '0 6px 30px rgba(105, 223, 208, 0.6)' }}
                        >
                            Know More
                        </Button>
                    </div>
                    <div className=" text-center w-[100%] md:w-[30%]">
                        <img src="/images/cto3.png" alt="rutain pharma" className="mx-auto"/>
                        <h2 className="text-[36px] mt-3 font-inter font-bold text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA]">DR. Ipsum</h2>
                        <h4 className="text-[#175856] font-semibold mt-2 mx-5 border-b-[2px] pb-3 border-[#AFAFAF]">CTO</h4>
                        <p className="mt-3 w-[70%] text-[#072C2C] mx-auto">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                        <Button
                            shape="round"
                            className="min-w-[118px] mx-auto mt-5 rounded-[12px] px-5 font-poppins font-medium text-[#55021A] bg-[#FECCE3] shadow-[0_4px_163px_rgba(233,31,100,0.2)] hover:shadow-[0_4px_163px_rgba(233,31,100,1)] transition-all duration-300 ease-in-out"
                        //   style={{ boxShadow: '0 6px 30px rgba(233, 31, 100, 0.5)' }} // Inline shadow
                        >
                            Know More
                        </Button>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
export default CompanyProfile