
import { useEffect, useRef, useState } from "react"
import { Button } from "../Component/Button"
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const ContactUs = () => {

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        message: "",
        company: "rutain",
    });
    const [submitted, setSubmitted] = useState(false);

    const contactRef = useRef(null); // Ref to the "Let’s Get In Touch" section

    const scrollToContact = () => {
        if (contactRef.current) {
            contactRef.current.scrollIntoView({
                behavior: "smooth", // smooth scrolling
                block: "start", // align at the start of the section
            });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { firstName, lastName, email, mobileNumber, message } = formData;
        if (!firstName || !lastName || !email || !mobileNumber || !message) {
        alert("Please fill out all fields before submitting.");
        return;
        }
        setSubmitted(true);
        try {
            const res = await fetch('https://39w5n8hf91.execute-api.ap-south-1.amazonaws.com/contact-us/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            // console.log(res)
            if (res.ok) {
                // console.log("Form submitted successfully");
                toast.success("Form submitted successfully.")
            } else {
                // console.error("Failed to submit form");
                toast.error("Failed to submit form.")
            }
            setFormData({
                firstName: "",
                lastName: "",
                email: "",
                mobileNumber: "",
                message: "",
                company: "rutain",
            })
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setSubmitted(false);
        }
    };

    useEffect(() => {
        // window.scrollTo(0, 0)
        scrollToContact()
    }, [])
    return (
        <div>
            <ToastContainer/>
            <div className="w-full relative h-[50vh] sm:h-[60vh] lg:h-[70vh]">
                <img src='/images/contact.png' alt="rutain pharma" className="w-full h-[100%] " />
                <div className="2xl:text-[94px] md:left-[35%]  w-[50%] md:w-[35%]  text-[32px] md:text-[52px] font-poppins font-bold text-transparent bg-clip-text  bg-gradient-to-r from-[#25BEB2] to-[#A1EEE1] absolute top-1/2 left-1/2 transform -translate-x-[0%] -translate-y-[50%] text-center">
                    Contact US
                </div>
            </div>
            <div>
                {/* <h1 className=" text-center mt-20 mb-10 text-[52px] font-poppins font-bold text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA]">Related Blogs</h1> */}
                <h1 className="relative text-center mt-20 mb-10 w-fit mx-auto text-[40px] lg:text-[52px] font-poppins font-bold text-transparent my-1 bg-clip-text bg-gradient-to-r from-[#175856] to-[#32BEBA]">Our Location
                    <img src="/images/img_three_hexagons.svg" alt="rutain-pharma" className="absolute top-[-5px] right-[-15px] w-4" />
                </h1>
                <div className="w-full mx-auto h-[500px] mt-10">
                    <div className="mapouter">
                        <div className="gmap_canvas">
                            <iframe
                                width="100%"
                                height="500"
                                id="gmap_canvas"
                                src="https://maps.google.com/maps?q=rutain%20pharma&t=&z=17&ie=UTF8&iwloc=&output=embed"
                                frameBorder="0"
                                scrolling="no"
                                marginHeight="0"
                                marginWidth="0"
                                style={{ border: 0 }}
                                title="Rutain Pharma Location"
                            ></iframe>
                            <style>{`
      .mapouter {
        position: relative;
        text-align: right;
        height: 500px;
        width: 100%;
      }
      .gmap_canvas {
        overflow: hidden;
        background: none !important;
        height: 500px;
        width: 100%;
      }
    `}</style>
                        </div>
                    </div>
                </div>

            </div>
            <div className="container">

                <div
                    className="  my-[50px] text-[#175856] transform transition-all duration-300 ease-in-out hover:scale-105"
                    style={{
                        boxShadow: '10px 10px 0px rgba(105, 223, 208, 0.5)',
                    }}
                >
                    <p className="text-[20px] my-5">Rutain Pharma - Headquarters</p>
                    <p className="text-[20px] flex items-start mt-5 gap-2 items-center">
                        <span>
                            <img src="images/location1.svg" className="w-14 mt-1" alt="rutain pharma" />
                        </span>
                        <span>B No. Plot No. 4307 - 4308, GIDC, Ankleshwar - 393002, Gujarat, India</span>
                    </p>
                    <p className="text-[20px] flex items-start mt-5 gap-2">
                        <span>
                            <img src="/images/call.svg" className="w-5 mt-1" alt="rutain pharma" />
                        </span>
                        <span>08045477087</span>
                    </p>
                    <p className="text-[20px] flex items-start mt-5 gap-2">
                        <span>
                            <img src="/images/mail.svg" className="w-5 mt-1" alt="rutain pharma" />
                        </span>
                        <span>info@rutainpharmachem.com</span>
                    </p>
                    <p className="text-[20px] flex items-start mt-5 pb-5 gap-2">
                        <span>
                            <img src="/images/time.svg" className="w-5 mt-1" alt="rutain pharma" />
                        </span>
                        <span>Monday to Friday: 8 am to 5 pm CET</span>
                    </p>
                </div>
            </div>


            <div>

            </div>
            <div className="w-full bg-[#072C2C] py-20 relative" ref={contactRef}>

                <img src="/images/bgLeaf.png" alt="rutain-pharma" className="absolute w-[20%] top-0 left-0" />
                <img src="/images/bgLeaf2.png" alt="rutain-pharma" className="absolute w-[15%] bottom-0 right-0" />
                <div className='w-[100%] md:w-[70%] mx-auto '>

                    <div className="mx-auto w-fit  my-3 mt-20 relative ">
                        <h1 className="relative text-[20px] md:text-[38px] font-bold font-poppins text-transparent bg-clip-text bg-gradient-to-r from-[#D0F7F0] to-[#25BEB2]">
                            Let’s Get In Touch
                        </h1>
                    </div>
                    <div className="flex flex-col md:flex-row gap-5 md:gap-0 justify-center md:justify-between my-5 px-5 md:px-0">
                        <input
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            className="px-10 py-3 rounded-full border-2 w-[98%] md:w-[48%] text-white border-[#175856]"
                        />
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            value={formData.lastName}
                            onChange={handleInputChange}
                            className="px-10 py-3 rounded-full border-2 w-[98%] md:w-[48%] text-white border-[#175856]"
                        />
                    </div>
                    <div className="flex flex-col md:flex-row gap-5 md:gap-0 justify-center md:justify-between my-5 px-5 md:px-0">
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleInputChange}
                            className="px-10 py-3 rounded-full border-2 w-[98%] md:w-[48%] text-white border-[#175856]"
                        />
                        <input
                            type="number"
                            name="mobileNumber"
                            placeholder="Mobile Number"
                            value={formData.mobileNumber}
                            onChange={handleInputChange}
                            className="px-10 py-3 rounded-full border-2 w-[98%] md:w-[48%] text-white border-[#175856]"
                        />
                    </div>

                    <textarea
                        rows={5}
                        name="message"
                        placeholder="More about your requirements..."
                        value={formData.message}
                        onChange={handleInputChange}
                        className="px-[70px] py-3 mt-2 rounded-full border-2 w-[100%] text-white bg-transparent border-[#175856]"
                    />
                    <div className="flex justify-center">

                    <div className="flex justify-center">
                        <Button
                            onClick={handleSubmit}
                            shape="round"
                            className="my-5 min-w-[118px] rounded-[12px] px-5 font-poppins font-medium text-black bg-[#a1eee1] hover:shadow-[0_4px_110px_rgba(105,223,208,1)] transition-all duration-300 ease-in-out "
                            disabled={submitted}
                        >
                            {submitted ? "Submitting..." : "Send Inquiry"}
                        </Button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs