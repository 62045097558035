import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { ProjectRoutes } from './Routes';
import Header from './Component/Header';
import Footer from './Component/Footer';


function App() {
  return (
    <div className='overflow-hidden'>
    <BrowserRouter>
    <div className=''>
    <Header />
    </div>
      <ProjectRoutes/>
      <Footer/>
    </BrowserRouter>
    </div>
  );
}

export default App;
