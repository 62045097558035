import { useRoutes } from "react-router-dom"
import CategoryProducts from "./Pages/AllProduct"
import BlogDetails from "./Pages/BlogDetails"
import Blogs from "./Pages/Blogs"
import CompanyProfile from "./Pages/CompanyProfile"
import ContactUs from "./Pages/ContactUs"
import { Home } from "./Pages/Home"
import { NotFound } from "./Pages/NotFound"
import Product from "./Pages/Product"
import ProductDetails from "./Pages/ProductDetail"
import BlogDetails1 from "./Pages/BlogDetails1"
import BlogDetails2 from "./Pages/BlogDetails2"

export const ProjectRoutes = ()=>{
    let element = useRoutes([
        {path:'/',element:<Home/>},
        {path:'/company-profile',element:<CompanyProfile/>},
        {path:'/product',element:<Product/>},
        {path:'/product-details/:product/:category',element:<ProductDetails/>},
        {path:'/product/:categoryName',element:<CategoryProducts />},
        {path:'/blogs',element:<Blogs/>},
        {path:'/blog-details',element:<BlogDetails/>},
        {path:'/blog-details1',element:<BlogDetails1/>},
        {path:'/blog-details2',element:<BlogDetails2/>},
        {path:'/contact-us',element:<ContactUs/>},
        {path:'*',element:<NotFound/>},
    ])
    return element
}