import { useEffect, useRef, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom";

const BlogDetails = () => {
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const leftDivRef = useRef(null);
    const rightDivRef = useRef(null);
    const [stickyStyle, setStickyStyle] = useState({});

    const [lastScrollTop, setLastScrollTop] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            if (!leftDivRef.current || !rightDivRef.current) return;

            const leftDiv = leftDivRef.current;
            const rightDiv = rightDivRef.current;

            const leftDivTop = leftDiv.getBoundingClientRect().top;
            const rightDivBottom = rightDiv.getBoundingClientRect().bottom;

            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const scrollingDown = scrollTop > lastScrollTop;
            console.log(leftDiv.current, rightDiv.current, leftDivTop, rightDivBottom, scrollTop, scrollingDown, 'scroll')
            setLastScrollTop(scrollTop);


            if (scrollTop < 820) {
                console.log('1')
                setStickyStyle({
                    position: 'relative'
                });
                return
            }
            // Start sticky when left div reaches the top of the viewport
            if (leftDivTop <= 10 && rightDivBottom * 6 > window.innerHeight) {
                setStickyStyle({
                    position: "fixed",
                    top: "10px",
                });
            }
            // Stop sticky when the bottom of the right div reaches the bottom of the viewport
            else if (rightDivBottom <= window.innerHeight && scrollingDown) {
                setStickyStyle({
                    position: "absolute",
                    bottom: "0px",
                    top: "auto",
                });
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [lastScrollTop]);


    return (
        <div>
            <div className="h-[80vh] overflow-hidden relative" >
                <img src="/images/blog1.png" alt="rutain-pharma" className="w-full h-full" />
                <div className="absolute inset-0 bg-gradient-to-t from-black/100 to-transparent opacity-90 "></div>
                <div className="absolute bottom-0 z-10 px-2 md:px-10 2xl:px-44 py-3 mb-5 md:mb-16"> {/* Add z-index for text visibility */}
                    <p className="font-inter text-[18px] md:text-[24px] 2xl:text-[38px] text-transparent my-1 bg-clip-text bg-gradient-to-r from-[#3AC7B9] to-[#A2E1DF]">
                        Celebrating the Backbone of Healthcare: World Pharmacist Day
                    </p>
                    <p className="font-inter text-[14px] md:text-[16px] text-white my-5 2xl:w-[70%] w-[90%]">
                        September 25th marks World Pharmacist Day, a global celebration of the vital role pharmacists play in ensuring the health and wellbeing of communities around the world. This year's theme, "[Theme]," highlights the importance of pharmacists in [specific area of focus].
                    </p>
                    <div className="flex gap-5 items-center">
                        <p className="text-white">5 minutes read</p>
                        <p className="text-white">25th  Sep, 2024</p>
                    </div>
                </div>
            </div>
            <div className="container" >
                <h1 className=" text-center mt-20 mb-10 text-[48px] font-poppins font-extrabold text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA]">Content</h1>
                <div className=" flex flex-col md:flex-row gap-10 md:gap-0  " >
                    {/* <div className=" w-full md:w-[30%] ">
                        <div ref={leftDivRef}
                             style={{ ...stickyStyle}}
          className="transition-all"
                        >
                            <h2 className="text-[20px] font-inter font-semibold text-transparent bg-clip-text bg-gradient-to-r from-[#175856] to-[#32BEBA]">
                                Table of Contents
                            </h2>
                            <ol className="list-decimal ml-5 mt-2">
                                <li className="text-[18px] mt-2"><a href="#c1">Content - 1</a></li>
                                <li className="text-[18px] mt-2"><a href="#c2">Content - 2</a></li>
                            </ol>
                        </div>
                    </div> */}

                    {/* <div className="w-[100%] md:w-[70%] " ref={rightDivRef}>
                        <p className="pb-10 border-b">
                        From ancient civilizations to modern-day healthcare systems, pharmacists have played a crucial role in the development and delivery of medicines. Today, pharmacists are not only dispensers of medication but also educators, researchers, and advocates for public health.
                        </p>
                        <div>
                            <div className="w-fit my-5">
                                <h1 className="relative text-[22px] font-semibold font-inter text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA]" id="c1">Content-1
                                    <img src="/images/img_three_hexagons.svg" alt="rutain-pharma" className="absolute top-[-5px] right-[-15px] w-4" />
                                </h1>
                            </div>
                            <p className="pb-5">
                                Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since, Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since,Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since
                            </p>
                            <p className="pb-5">
                                Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since, Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since,Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since
                            </p>
                            <p className="pb-5">
                                Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since, Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since,Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since
                            </p>
                        </div>
                        <div>
                            <div className="w-fit my-5">
                                <h1 className="relative text-[22px] font-semibold font-inter text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA]" id="c2">Content-2
                                    <img src="/images/img_three_hexagons.svg" alt="rutain-pharma" className="absolute top-[-5px] right-[-15px] w-4" />
                                </h1>
                            </div>
                            <p className="pb-5">
                                Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since, Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since,Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since
                            </p>
                            <p className="pb-5">
                                Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since, Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since,Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since
                            </p>
                            <p className="pb-5">
                                Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since, Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since,Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since
                            </p>
                        </div>
                        <div className="mx-auto w-[100%]">
                            <img src="/images/blog.png" alt="rutain pharma" className="mx-auto my-3 mb-5" />
                            <p className="pb-5">
                                Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since, Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since,Cera delivers 50 million at-home patient visits since 50 million at-home patient visits since 50 million at-home patient visits since
                            </p>
                        </div>
                    </div> */}
                    <div className="">
                        <div className="">
                            <h1 className="text-2xl font-bold  mb-4">
                                Pharmacists' Impact on Healthcare
                            </h1>

                            {/* Impact Section */}
                            <section className="mb-6">
                                <h2 className="text-xl font-semibold mb-2">
                                    Pharmacists make a significant impact on healthcare outcomes
                                    through:
                                </h2>
                                <ul className="list-decimal list-inside space-y-2">
                                    <li>
                                        <strong>Medication Management:</strong> Ensuring safe and
                                        effective use of medications.
                                    </li>
                                    <li>
                                        <strong>Patient Education:</strong> Empowering patients to take
                                        control of their health.
                                    </li>
                                    <li>
                                        <strong>Research and Development:</strong> Contributing to the
                                        discovery of new treatments and therapies.
                                    </li>
                                    <li>
                                        <strong>Public Health Initiatives:</strong> Promoting health
                                        awareness and disease prevention.
                                    </li>
                                </ul>
                            </section>

                            {/* Challenges Section */}
                            <section className="mb-6">
                                <h2 className="text-xl font-semibold mb-2">Challenges Facing Pharmacists:</h2>
                                <p className="mb-2">
                                    Despite their vital role, pharmacists face numerous challenges,
                                    including:
                                </p>
                                <ul className="list-decimal list-inside space-y-2">
                                    <li>
                                        <strong>Workforce Shortages:</strong> Insufficient staffing levels
                                        impacting patient care.
                                    </li>
                                    <li>
                                        <strong>Funding Constraints:</strong> Limited resources hindering
                                        the development of new services.
                                    </li>
                                    <li>
                                        <strong>Regulatory Pressures:</strong> Ever-changing regulations
                                        and guidelines.
                                    </li>
                                </ul>
                            </section>

                            {/* Conclusion Section */}
                            <section className="mb-6">
                                <h2 className="text-xl font-semibold mb-2">Conclusion:</h2>
                                <p className="mb-4">
                                    On World Pharmacist Day, we recognize the dedication, expertise, and
                                    compassion of pharmacists worldwide. As we celebrate their
                                    contributions, we must also acknowledge the challenges they face and
                                    work together to address them. By supporting pharmacists, we can
                                    ensure better health outcomes for individuals and communities
                                    everywhere.
                                </p>
                            </section>

                            {/* Call to Action Section */}
                            <section>
                                <h2 className="text-xl font-semibold mb-2">Call to Action:</h2>
                                <p className="mb-2">Let's show our appreciation for pharmacists by:</p>
                                <ul className="list-decimal list-inside space-y-2">
                                    <li>Sharing stories of pharmacists making a difference.</li>
                                    <li>
                                        Raising awareness about the importance of pharmacists in
                                        healthcare.
                                    </li>
                                </ul>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                {/* <h1 className=" text-center mt-20 mb-10 text-[52px] font-poppins font-bold text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA]">Related Blogs</h1> */}
                <h1 className="relative text-center mt-20 mb-10 w-fit mx-auto text-[48px] font-poppins font-bold text-transparent my-1 bg-clip-text bg-gradient-to-r from-[#175856] to-[#32BEBA]">Related Blogs
                    <img src="/images/img_three_hexagons.svg" alt="rutain-pharma" className="absolute top-[-5px] right-[-20px] w-6" />
                </h1>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 container mx-auto gap-5 md:gap-10 h-fit">
                <div className="group overflow-hidden  mb-10 relative" style={{ boxShadow: '10px 10px 0px rgba(254, 204, 227)' }}>
                    <img src="/images/blog.png" alt="rutain-pharma" className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110" />
                    <div className="absolute inset-0 bg-black opacity-70"></div> {/* Dark overlay */}
                    <p className="absolute right-4 top-3 text-[#fff]">30th Sep, 2024</p>
                    <div className="absolute bottom-0 z-10 px-10 py-3"> {/* Add z-index for text visibility */}
                        <p className="font-inter 2xl:text-[24px] text-transparent my-1 bg-clip-text bg-gradient-to-r from-[#DE5996] to-[#FECCE3]">
                        Safe and Effective Medication: Tips and Best Practices
                        </p>
                        <div className="flex gap-5 ">
                            <p className="flex gap-1 hover:border-b border-[#FECCE3] underline text-transparent bg-clip-text bg-gradient-to-r from-[#DE5996] to-[#FECCE3] cursor-pointer" onClick={() => navigate('/blog-details1')}>
                                Read More <img src="/images/upArrow.svg" alt="rutain-pharma" className="w-4" />
                            </p>
                            <p className="text-white">5 minutes read</p>
                        </div>
                    </div>
                </div>
                <div className="group  mb-10 overflow-hidden relative h-fit" style={{ boxShadow: '10px 10px 0px rgba(254, 204, 227)' }}>
                    <img src="/images/blog5.png" alt="rutain-pharma" className="w-full h-full transition-transform duration-300 group-hover:scale-110" />
                    <div className="absolute inset-0 bg-black opacity-70"></div> {/* Dark overlay */}
                    <p className="absolute right-4 top-3 text-[#fff]">30th Sep, 2024</p>
                    <div className="absolute bottom-0 z-10 px-10 py-3"> {/* Add z-index for text visibility */}
                        <p className="font-inter 2xl:text-[24px] text-transparent my-1 bg-clip-text bg-gradient-to-r from-[#DE5996] to-[#FECCE3]">
                        Overview of the Pharma Industry
                        </p>
                        <div className="flex gap-5">
                            <p className="flex gap-1 hover:border-b border-[#FECCE3] underline text-transparent bg-clip-text bg-gradient-to-r from-[#DE5996] to-[#FECCE3] cursor-pointer" onClick={() => navigate('/blog-details2')}>
                                
                                Read More <img src="/images/upArrow.svg" alt="rutain-pharma" className="w-4" />
                            </p>
                            <p className="text-white">5 minutes read</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BlogDetails