import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Slider from "react-slick"
import { Button } from "../Component/Button"
import ProductList from "../Component/ProductList"



const Product = () => {
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        cssEase: "linear",
    };
    return (
        <div>
            {/* <MyCarousel/> */}
            <div className="w-full relative h-[50vh] sm:h-[60vh] lg:h-[70vh]">
                <img src='/images/productBanner.png' alt="rutain pharma" className="w-full h-[100%] " />
                <div className="2xl:text-[94px] md:left-[35%]  w-[50%] md:w-[35%]  text-[32px] md:text-[52px] font-poppins font-bold text-transparent bg-clip-text  bg-gradient-to-r from-[#25BEB2] to-[#A1EEE1] absolute top-1/2 left-1/2 transform -translate-x-[0%] -translate-y-[50%] text-center">
                    Our Products
                </div>
            </div>
            <div className=" relative h-[100vh] bg-[#072C2C]">
                <img src="/images/bgLeaf.png" alt="rutain-pharma" className="absolute top-0 left-0 z-[5] hidden md:block" />
                <img src="/images/bgLeaf2.png" alt="rutain-pharma" className="absolute bottom-0 right-0 z-[5] hidden md:block" />

                <div className="container h-full">
                    <Slider {...settings} className="">
                        <div className="carousel-slide">
                            <div className="flex w-[98%] mx-auto items-center h-full  justify-between bg-[#072C2C]">
                                <div className="w-[100%] lg:w-[60%] relative ">
                                    <h1 className="text-[30px] lg:text-[48px] my-5 font-bold font-poppins text-transparent bg-clip-text bg-gradient-to-r from-[#D0F7F0] to-[#25BEB2]">
                                        API Intermediates
                                    </h1>
                                    <p className="text-[14px] lg:text-[16px] font-poppins leading-[1.7] text-[#F1FCF9] bg-clip-text bg-gradient-to-r from-[#D0F7F0] to-[#25BEB2]">
                                        Pharmaceutical Chemicals we offer are demanded in the industries of pharmaceutical. They are helpful for the diagnosis, prevention, and treatment of diseases. They have the attributes for restoring, modifying, and correcting several organic functions. They are used in pharmaceutical industry for the formation of several types of medicines. Pharmaceutical chemicals are widely used by humans for rapid food production. They are special types of chemicals, which help in formulation of drugs. Offered compounds are accessible with disinfectant efficiency as well as good residual performance. They are used to make drugs for pain, allergy, and other conditions.
                                    </p>
                                    <Button
                                        shape="round"
                                        className="min-w-[118px]  mt-5 rounded-[12px] px-5 font-poppins font-medium text-[#072C2C] bg-[#A1EEE1] shadow-[0_4px_163px_rgba(105,223,208,0.6)] hover:shadow-[0_4px_110px_rgba(105,223,208,1)] transition-all duration-300 ease-in-out"
                                        //   style={{ boxShadow: '0 6px 30px rgba(105, 223, 208, 0.6)' }}
                                        onClick={() => navigate('/product/API-Intermediates')}
                                    >
                                        Know More
                                    </Button>
                                </div>
                                {/* <div className="w-[50%] md:flex justify-end relative  hidden ">
                        <img src="/images/product1.png" alt="rutain-pharma" className="z-[20]" />
                    </div> */}
                                <div
                                    className="rounded-[10%] overflow-hidden cursor-pointer h-[500px] relative lg:w-[30%] w-[0%] hidden md:block"
                                    style={{ boxShadow: '7px 7px 0px rgba(105, 223, 208, 0.6)', }}
                                    onClick={() => navigate('/product/API-Intermediates')}
                                >
                                    <video
                                        className="absolute top-0  left-0 w-full h-full object-cover transform transition-all duration-300 ease-in-out hover:scale-110"
                                        controls={false}
                                        loop
                                        autoPlay
                                        muted
                                    >
                                        <source src="https://rutain-data.s3.ap-south-1.amazonaws.com/intermediate.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-slide">
                            <div className="flex w-[98%] mx-auto items-center h-full  justify-between bg-[#072C2C]">
                                <div className="w-[100%] lg:w-[60%] relative ">
                                    <h1 className="text-[30px] lg:text-[48px] my-5 font-bold font-poppins text-transparent bg-clip-text bg-gradient-to-r from-[#D0F7F0] to-[#25BEB2]">
                                        Bulk Drugs API
                                    </h1>
                                    <p className="text-[14px] md:text-[16px] font-poppins leading-[1.7] text-[#F1FCF9] bg-clip-text bg-gradient-to-r from-[#D0F7F0] to-[#25BEB2]">
                                        Pharmaceutical Chemicals we offer are demanded in the industries of pharmaceutical. They are helpful for the diagnosis, prevention, and treatment of diseases. They have the attributes for restoring, modifying, and correcting several organic functions. They are used in pharmaceutical industry for the formation of several types of medicines. Pharmaceutical chemicals are widely used by humans for rapid food production. They are special types of chemicals, which help in formulation of drugs. Offered compounds are accessible with disinfectant efficiency as well as good residual performance. They are used to make drugs for pain, allergy, and other conditions.
                                    </p>
                                    <Button
                                        shape="round"
                                        className="min-w-[118px]  mt-5 rounded-[12px] px-5 font-poppins font-medium text-[#072C2C] bg-[#A1EEE1] shadow-[0_4px_163px_rgba(105,223,208,0.6)] hover:shadow-[0_4px_110px_rgba(105,223,208,1)] transition-all duration-300 ease-in-out"
                                        //   style={{ boxShadow: '0 6px 30px rgba(105, 223, 208, 0.6)' }}
                                        onClick={() => navigate('/product/Bulk-Drugs-API')}
                                    >
                                        Know More
                                    </Button>
                                </div>
                                {/* <div className="w-[50%] md:flex justify-end relative  hidden ">
                        <img src="/images/product1.png" alt="rutain-pharma" className="z-[20]" />
                    </div> */}
                                <div
                                    className="rounded-[10%] overflow-hidden cursor-pointer h-[500px] relative lg:w-[30%] w-[0%] hidden md:block"
                                    style={{ boxShadow: '7px 7px 0px rgba(105, 223, 208, 0.6)' }}
                                    onClick={() => navigate('/product/Bulk-Drugs-API')}
                                >
                                    <video
                                        className="absolute top-0  left-0 w-full h-full object-cover transform transition-all duration-300 ease-in-out hover:scale-110"
                                        controls={false}
                                        loop
                                        autoPlay
                                        muted
                                    >
                                        <source src="https://rutain-data.s3.ap-south-1.amazonaws.com/drugs.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-slide">
                            <div className="flex w-[98%] mx-auto items-center h-full  justify-between bg-[#072C2C]">
                                <div className="w-[100%] lg:w-[60%] relative ">
                                    <h1 className="text-[30px] lg:text-[48px] my-5 font-bold font-poppins text-transparent bg-clip-text bg-gradient-to-r from-[#D0F7F0] to-[#25BEB2]">
                                        API Product
                                    </h1>
                                    <p className="text-[14px] md:text-[16px] font-poppins leading-[1.7] text-[#F1FCF9] bg-clip-text bg-gradient-to-r from-[#D0F7F0] to-[#25BEB2]">
                                        Pharmaceutical Chemicals we offer are demanded in the industries of pharmaceutical. They are helpful for the diagnosis, prevention, and treatment of diseases. They have the attributes for restoring, modifying, and correcting several organic functions. They are used in pharmaceutical industry for the formation of several types of medicines. Pharmaceutical chemicals are widely used by humans for rapid food production. They are special types of chemicals, which help in formulation of drugs. Offered compounds are accessible with disinfectant efficiency as well as good residual performance. They are used to make drugs for pain, allergy, and other conditions.
                                    </p>
                                    <Button
                                        shape="round"
                                        className="min-w-[118px]  mt-5 rounded-[12px] px-5 font-poppins font-medium text-[#072C2C] bg-[#A1EEE1] shadow-[0_4px_163px_rgba(105,223,208,0.6)] hover:shadow-[0_4px_110px_rgba(105,223,208,1)] transition-all duration-300 ease-in-out"
                                        //   style={{ boxShadow: '0 6px 30px rgba(105, 223, 208, 0.6)' }}
                                        onClick={() => navigate('/product/API-Intermediates')}
                                    >
                                        Know More
                                    </Button>
                                </div>
                                {/* <div className="w-[50%] md:flex justify-end relative  hidden ">
                        <img src="/images/product1.png" alt="rutain-pharma" className="z-[20]" />
                    </div> */}
                                <div
                                    className="rounded-[10%] overflow-hidden cursor-pointer h-[500px] relative lg:w-[30%] w-[0%] hidden md:block"
                                    style={{ boxShadow: '7px 7px 0px rgba(105, 223, 208, 0.6)' }}
                                    onClick={() => navigate('/product/API-Intermediates')}
                                >
                                    <video
                                        className="absolute top-0  left-0 w-full h-full object-cover transform transition-all duration-300 ease-in-out hover:scale-110"
                                        controls={false}
                                        loop
                                        autoPlay
                                        muted
                                    >
                                        <source src="https://rutain-data.s3.ap-south-1.amazonaws.com/api.mp4" type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
            <div className="container">
                <ProductList />
            </div>
        </div>
    )
}
export default Product