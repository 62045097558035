import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

const BlogDetails2 = () => {
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const leftDivRef = useRef(null);
    const rightDivRef = useRef(null);
    const [stickyStyle, setStickyStyle] = useState({});

    const [lastScrollTop, setLastScrollTop] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            if (!leftDivRef.current || !rightDivRef.current) return;

            const leftDiv = leftDivRef.current;
            const rightDiv = rightDivRef.current;

            const leftDivTop = leftDiv.getBoundingClientRect().top;
            const rightDivBottom = rightDiv.getBoundingClientRect().bottom;

            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const scrollingDown = scrollTop > lastScrollTop;
            console.log(leftDiv.current, rightDiv.current, leftDivTop, rightDivBottom, scrollTop, scrollingDown, 'scroll')
            setLastScrollTop(scrollTop);


            if (scrollTop < 820) {
                console.log('1')
                setStickyStyle({
                    position: 'relative'
                });
                return
            }
            // Start sticky when left div reaches the top of the viewport
            if (leftDivTop <= 10 && rightDivBottom * 6 > window.innerHeight) {
                setStickyStyle({
                    position: "fixed",
                    top: "10px",
                });
            }
            // Stop sticky when the bottom of the right div reaches the bottom of the viewport
            else if (rightDivBottom <= window.innerHeight && scrollingDown) {
                setStickyStyle({
                    position: "absolute",
                    bottom: "0px",
                    top: "auto",
                });
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [lastScrollTop]);


    return (
        <div>
            <div className="h-[80vh] overflow-hidden relative" >
                <img src="/images/blog1.png" alt="rutain-pharma" className="w-full h-full" />
                <div className="absolute inset-0 bg-gradient-to-t from-black/100 to-transparent opacity-90 "></div>
                <div className="absolute bottom-0 z-10 px-2 md:px-10 2xl:px-44 py-3 mb-5 md:mb-16"> {/* Add z-index for text visibility */}
                    <p className="font-inter text-[18px] md:text-[24px] 2xl:text-[38px] text-transparent my-1 bg-clip-text bg-gradient-to-r from-[#3AC7B9] to-[#A2E1DF]">
                    Overview of the Pharma Industry
                    </p>
                    <p className="font-inter text-[14px] md:text-[16px] text-white my-5 2xl:w-[70%] w-[90%]">
                        Medications play a vital role in treating various health conditions. However, improper use or management of medications can lead to adverse reactions, interactions, or even harm. In this blog post, we will discuss some essential tips and best practices for safe and effective medication management.
                    </p>
                    <div className="flex gap-5 items-center">
                        <p className="text-white">5 minutes read</p>
                        <p className="text-white">25th  Sep, 2024</p>
                    </div>
                </div>
            </div>
            <div className="container" >
                <h1 className=" text-center mt-20 mb-10 text-[48px] font-poppins font-extrabold text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA]">Content</h1>
                <div className=" flex flex-col md:flex-row gap-10 md:gap-0  " >
                    <div className="">
                        {/* <h1 className="text-2xl font-bold  mb-4">
                                Pharmacists' Impact on Healthcare
                            </h1> */}

                        {/* Impact Section */}
                        <section className="mb-6">
                            <div className="">
                                <p className=" text-xl font-semibold mb-6">
                                Market Size and Growth
                                </p>
                                <p className="">
                                The global pharmaceutical market size was valued at USD 1.42 trillion in 2022 and is expected to grow at a compound annual growth rate (CAGR) of 8.5% from 2023 to 2030.
                                </p>
                                <div className="flex gap-5 items-center">
                                    <p className="text-white">5 minutes read</p>
                                    <p className="text-white">25th  Sep, 2024</p>
                                </div>
                            </div>
                        </section>

                        {/* Challenges Section */}
                        <section className="mb-6">
                            <h2 className="text-xl font-semibold mb-6">Segments of the Pharma Industry</h2>
                            <ul className="list-decimal list-inside space-y-2 mb-6">
                                <li className="mb-2"><strong> Prescription Drugs:</strong> These are drugs that can be purchased without a prescription.</li>
                                <li className="mb-2"><strong> Over-the-Counter (OTC) Drugs:</strong> These are drugs that can only be obtained with a prescription from a licensed medical professional.</li>
                                <li className="mb-2"><strong> Biologics:</strong> These are drugs that are derived from living organisms, such as vaccines and gene therapies</li>
                                <li className="mb-2"><strong> Generics</strong> These are copies of brand-name drugs that have the same active ingredients, dosage, and administration.</li>
                            </ul>
                        </section>
                        <section className="mb-6">
                            <h2 className="text-xl font-semibold mb-6">Trends in the Pharma Industry</h2>
                            <ul className="list-decimal list-inside space-y-2 mb-6">
                                <li className="mb-2"><strong> Personalized Medicine:</strong> The use of genetic testing and other technologies to tailor treatments to individual patients.</li>
                                <li className="mb-2"><strong> Digital Health:</strong> The use of digital technologies, such as telemedicine and mobile health apps, to improve patient outcomes.</li>
                                <li className="mb-2"><strong> Sustainability:</strong> The adoption of environmentally friendly practices and supply chain management.</li>
                                <li className="mb-2"><strong> Artificial Intelligence (AI):</strong> The use of AI and machine learning to improve drug discovery, development, and manufacturing.</li>
                            </ul>
                        </section>
                        <section className="mb-6">
                            <h2 className="text-xl font-semibold mb-6">Challenges in the Pharma Industry</h2>
                            <ul className="list-decimal list-inside space-y-2 mb-6">
                                <li className="mb-2"><strong> Regulatory Compliance:</strong>The need to comply with complex and evolving regulatory requirements.</li>
                                <li className="mb-2"><strong> High Research and Development (R&D) Costs:</strong> The cost of discovering and developing new drugs can be prohibitively expensive.</li>
                                <li className="mb-2"><strong> Patent Expirations:</strong> The loss of patent protection for branded drugs can lead to a decline in sales.</li>
                                <li className="mb-2"><strong> Counterfeiting:</strong> The production and distribution of fake or counterfeit drugs can pose a significant threat to public health.</li>
                            </ul>
                        </section>
                        <section className="mb-6">
                            <h2 className="text-xl font-semibold mb-6">Opportunities in the Pharma Industry</h2>
                            <ul className="list-decimal list-inside space-y-2 mb-6">
                                <li className="mb-2"><strong> Emerging Markets:</strong> The development of innovative therapies, such as gene editing and cell therapy, presents opportunities for pharmaceutical companies to develop new treatments.</li>
                                <li className="mb-2"><strong>Innovative Therapies: (OTC) Drugs:</strong> These are drugs that can only be obtained with a prescription from a licensed medical professional.</li>
                                <li className="mb-2"><strong> Digital Health: </strong> The adoption of digital health technologies presents opportunities for pharmaceutical companies to improve patient outcomes and reduce costs.</li>
                                <li className="mb-2"><strong> Partnerships and Collaborations: </strong> The formation of partnerships and collaborations between pharmaceutical companies, academia, and government presents opportunities for innovation and growth.</li>
                            </ul>
                        </section>
                    </div>
                </div>
            </div>
            <div className="container">
                {/* <h1 className=" text-center mt-20 mb-10 text-[52px] font-poppins font-bold text-transparent bg-clip-text  bg-gradient-to-r from-[#175856] to-[#32BEBA]">Related Blogs</h1> */}
                <h1 className="relative text-center mt-20 mb-10 w-fit mx-auto text-[48px] font-poppins font-bold text-transparent my-1 bg-clip-text bg-gradient-to-r from-[#175856] to-[#32BEBA]">Related Blogs
                    <img src="/images/img_three_hexagons.svg" alt="rutain-pharma" className="absolute top-[-5px] right-[-20px] w-6" />
                </h1>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 container mx-auto gap-5 md:gap-10 h-fit">
                <div className="group overflow-hidden  mb-10 relative" style={{ boxShadow: '10px 10px 0px rgba(254, 204, 227)' }}>
                    <img src="/images/blog.png" alt="rutain-pharma" className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110" />
                    <div className="absolute inset-0 bg-black opacity-70"></div> {/* Dark overlay */}
                    <p className="absolute right-4 top-3 text-[#fff]">30th Sep, 2024</p>
                    <div className="absolute bottom-0 z-10 px-10 py-3"> {/* Add z-index for text visibility */}
                        <p className="font-inter 2xl:text-[24px] text-transparent my-1 bg-clip-text bg-gradient-to-r from-[#DE5996] to-[#FECCE3]">
                        Celebrating the Backbone of Healthcare: World Pharmacist Day
                        </p>
                        <div className="flex gap-5 ">
                            <p className="flex gap-1 hover:border-b border-[#FECCE3] underline text-transparent bg-clip-text bg-gradient-to-r from-[#DE5996] to-[#FECCE3] cursor-pointer" onClick={() => navigate('/blog-details')}>
                                Read More <img src="/images/upArrow.svg" alt="rutain-pharma" className="w-4" />
                            </p>
                            <p className="text-white">5 minutes read</p>
                        </div>
                    </div>
                </div>
                <div className="group  mb-10 overflow-hidden relative h-fit" style={{ boxShadow: '10px 10px 0px rgba(254, 204, 227)' }}>
                    <img src="/images/blog5.png" alt="rutain-pharma" className="w-full h-full transition-transform duration-300 group-hover:scale-110" />
                    <div className="absolute inset-0 bg-black opacity-70"></div> {/* Dark overlay */}
                    <p className="absolute right-4 top-3 text-[#fff]">30th Sep, 2024</p>
                    <div className="absolute bottom-0 z-10 px-10 py-3"> {/* Add z-index for text visibility */}
                        <p className="font-inter 2xl:text-[24px] text-transparent my-1 bg-clip-text bg-gradient-to-r from-[#DE5996] to-[#FECCE3]">
                        Safe and Effective Medication: Tips and Best Practices
                        </p>
                        <div className="flex gap-5">
                            <p className="flex gap-1 hover:border-b border-[#FECCE3] underline text-transparent bg-clip-text bg-gradient-to-r from-[#DE5996] to-[#FECCE3] cursor-pointer" onClick={() => navigate('/blog-details1')}>
                                Read More <img src="/images/upArrow.svg" alt="rutain-pharma" className="w-4" />
                            </p>
                            <p className="text-white">5 minutes read</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BlogDetails2