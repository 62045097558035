import React, { useState } from 'react';
import { productData } from '../jsondata';
import { Button } from './Button';
import { useNavigate } from 'react-router-dom';

const ProductList = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(''); // New state for selected category

  // Flatten all products into a single array for easier search and display
  const allProducts = productData.flatMap((category) =>
    category.products.map((product) => ({
      ...product,
      category: category.category,
    }))
  );

  // Filter products based on search term and selected category
  const filteredProducts = allProducts.filter((product) =>
    (searchTerm === '' || product.name.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (selectedCategory === '' || product.category === selectedCategory)
  );

  // Group products by category for display
  const groupedProducts = productData.map((category) => ({
    categoryName: category.category,
    products: filteredProducts.filter((product) => product.category === category.category), // Removed `.slice(0, 4)`
  }));

  const handleSendInquiry = (product, category) => {
    navigate(`/product-details/${category.replaceAll(' ', '-')}/${product.name.replaceAll(' ', '-')}`, { state: { product } });
  };

  const handleCategoryClick = (categoryName) => {
    setSelectedCategory(categoryName === selectedCategory ? '' : categoryName);
  };

  return (
    <div>
      <div className="flex justify-between w-full items-center gap-5">
        <div className="w-[40%]">
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-[100%] p-2 px-5 border-2 border-[#188983] rounded-full focus:outline-none"
          />
        </div>
        <div className="flex gap-5">
          {/* <Button
            shape="round"
            className="min-w-[118px] mx-auto my-5 rounded-[12px] px-5 font-poppins font-medium text-black bg-[#A1EEE1] hover:shadow-[0_4px_110px_rgba(105,223,208,1)] transition-all duration-300 ease-in-out"
            onClick={() => setSelectedCategory('')} // Clear filter when 'See All' is clicked
          >
            See All
          </Button> */}
          {productData.map((category, index) => (
            <Button
              key={index}
              shape="round"
              className={`min-w-[118px] mx-auto my-5 rounded-[12px] px-5 font-poppins font-medium text-black bg-[#A1EEE1] hover:shadow-[0_4px_110px_rgba(105,223,208,1)] transition-all duration-300 ease-in-out ${selectedCategory === category.category ? 'bg-[#FECCE3] hover:shadow-[0_4px_163px_rgba(233,31,100,1)]' : ''
                }`}
              onClick={() => handleCategoryClick(category.category)} // Filter by category when button is clicked
            >
              {category.category}
            </Button>
          ))}
        </div>
        <Button
          shape="round"
          className="min-w-[118px] mx-auto my-5 rounded-[12px] px-5 font-poppins font-medium text-black bg-[#FECCE3] hover:shadow-[0_4px_163px_rgba(233,31,100,1)] transition-all duration-300 ease-in-out"
          onClick={() => {
            setSearchTerm('');
            setSelectedCategory('');
          }} // Clear both search term and category when 'Clear All' is clicked
        >
          Clear All
        </Button>
      </div>
      {groupedProducts.map((group, groupIndex) => (
        group.products.length > 0 && (
          <div key={groupIndex} className="mb-8">
            <p className="text-center text-[30px] md:text-[36px] md:my-5 font-bold font-poppins text-transparent bg-clip-text bg-gradient-to-r from-[#175856] to-[#32BEBA]">
              {group.categoryName}
            </p>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-y-5 md:gap-y-0">
              {group.products.map((product, index) => (
                <div
                  key={index}
                  className={`p-2 mt-3 md:mt-0 md:p-6 bg-white ${(index === 0 || index === 1) && 'border-b-[2px]'} ${index % 2 === 0 && 'md:border-r-[2px]'} border-[#DFDFDF]`}
                >
                  <h3 className="mb-2 text-[22px] border-[#AFAFAF] border-b-[2px] font-semibold font-poppins text-transparent bg-clip-text bg-gradient-to-r from-[#175856] to-[#32BEBA]">
                    {product.name}
                  </h3>
                  <div className="flex gap-5">
                    <div className='my-auto'>
                      <img src={product.image} alt='product' className=' mx-auto ' />
                    </div>
                    <div className="w-[60%]">
                      {product.casNo && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>CAS No: <span className='text-gray-600 text-right'>{product.casNo}</span></p>}
                      {product.storage && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Storage: <span className='text-gray-600 text-right'>{product.storage}</span></p>}
                      {product.color && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Color: <span className='text-gray-600 text-right'>{product.color}</span></p>}
                      {product.form && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Form: <span className='text-gray-600 text-right'>{product.form}</span></p>}
                      {product.smell && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Smell: <span className='text-gray-600 text-right'>{product.smell}</span></p>}
                      {product.placeOfOrigin && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Place of Origin: <span className='text-gray-600 text-right'>{product.placeOfOrigin}</span></p>}
                      {product.grade && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Grade: <span className='text-gray-600 text-right'>{product.grade}</span></p>}
                      {product.purity && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Purity: <span className='text-gray-600 text-right'>{product.purity}</span></p>}
                      {product.supplyAbility && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Supply Ability: <span className='text-gray-600 text-right'>{product.supplyAbility}</span></p>}
                      {product.application && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Application: <span className='text-gray-600 text-right'>{product.application}</span></p>}
                      {product.otherNames && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Other Names: <span className='text-gray-600 text-right'>{product.otherNames}</span></p>}
                      {product.molecularFormula && <p className={`text-black flex justify-between items-center border-b-[1px] border-[#E8E8E8] py-2`}>Molecular Formula: <span className='text-gray-600 text-right'>{product.molecularFormula}</span></p>}
                    </div>
                  </div>
                  <Button
                    shape="round"
                    className={`min-w-[118px] mx-auto my-5 rounded-[12px] px-5 font-poppins font-medium text-black ${index % 2 === 0 ? 'bg-[#A1EEE1] hover:shadow-[0_4px_110px_rgba(105,223,208,1)]' : 'bg-[#FECCE3] hover:shadow-[0_4px_163px_rgba(233,31,100,1)]'} transition-all duration-300 ease-in-out`}
                    onClick={() => handleSendInquiry(product, group.categoryName)}
                  >
                    More Info
                  </Button>
                </div>
              ))}
            </div>
          </div>
        )
      ))}
    </div>
  );
};

export default ProductList;